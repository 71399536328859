import React from "react";
import StatusCounter from "../common/StatusCounter";

const MultipleStatusCountersSection = () => {
  // Define the various status items
  const statuses = [
    { value: 50, suffix: "%", name: "Ave. Starting Score" },
    { value: 75, suffix: "%", name: "Ave. 3 Month Score" },
    { value: 90, suffix: "%", name: "Ave. 6 Month Score" },
    { value: 2, suffix: "rings", name: "Ave. Time to Answer" },
    { value: 90, suffix: "%", name: "First Call Resolution" },
    { value: 11, suffix: "mins", name: "Ave. Resolution Time" }
  ];

  return (
    <div className="bg-white w-full border-t border-gray-100 py-16">
      <div className="w-11/12 max-w-screen-lg mx-auto">
        <h2 className='text-4xl md:text-5xl text-site-lightblue text-center font-bold mb-8'>Proactive On IT Service Levels</h2>

        <p className='text-lg text-gray-800 text-center mb-2'>We quantitatively measure the health of your IT environment by assessing over 320 indicators and reporting out how your systems improve over time. The result is a stable IT environment with fewer issues and less risk. When issues come up, our average response and resolution times are best in class.</p>
      </div>

      <div
        className="w-11/12 max-w-screen-xl flex flex-row flex-wrap items-end space-x-20 space-y-6 justify-center mx-auto"
      >
        {statuses.map((status, idx) => (
          <StatusCounter
            key={idx}
            endValue={status.value}
            suffix={status.suffix}
            statusName={status.name}
            duration={4}
          />
        ))}
      </div>
    </div>
  );
};

export default MultipleStatusCountersSection;