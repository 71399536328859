import * as React from "react"

import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import HomeHeroSection from "../components/sections/home-hero-section"
import HomeIntroSectionNewUpdate from "../components/sections/home-intro-section-new-update"
import HomeChecklistSection from "../components/sections/home-checklist-section"
import HomeDavidSection from "../components/sections/home-david-section"
import HomeCtaSection from "../components/sections/home-cta-section"
import CapabilitiesSection from "../components/sections/capabilities-section"
import MultipleStatusCountersSection from "../components/sections/MultipleStatusCountersSection"

const HomeDemoPage = () => (
  <Layout>
    <Seo
      title="Fully Managed IT Network Support"
      noIndex
    />
    <HomeHeroSection />
    <HomeIntroSectionNewUpdate />
    <MultipleStatusCountersSection />
    <HomeChecklistSection />
    <HomeDavidSection />
    <HomeCtaSection />
    <CapabilitiesSection />
  </Layout>
)

export default HomeDemoPage
